.home {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100px;
  max-height: 1000px;
}

@media screen and (min-width: 769px) {
  .home {
    height: 600px;
  }
}

.home__banner, .home__title {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.home__title {
  top: 3em;
}

.home__banner {
  overflow-x: clip;
}

.home__window {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 65%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  height: 0em;
  width: 100%;
  overflow-y: visible;
  background-color: white;
}

.home .home-hide {
  display: none;
}

.home .home-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
