.circuit-specialise {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.circuit-specialise .container-title {
  width: 100%;
  margin: 1em 0em;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .circuit-specialise .container-title {
    width: 80%;
    margin: 2em 0em;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .circuit-specialise .container-title {
    width: 70%;
    margin: 3em 0em;
  }
}

@media screen and (min-width: 993px) {
  .circuit-specialise .container-title {
    width: 60%;
    margin: 10em 0em 5em 0em;
  }
}

.circuit-specialise .section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 6em 0em 0em 0em;
  background-color: #f3f3f3;
}

@media screen and (min-width: 769px) {
  .circuit-specialise .section {
    width: 60%;
  }
}

.circuit-specialise .container-undertitle {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1em;
  margin: 3em 0em;
  text-align: center;
  /*border-left: 5px solid map-get($colors-rule, second-color);
    border-right: 5px solid map-get($colors-rule, second-color);*/
  border-radius: 1em;
  border-bottom: 2px solid #EFA00B;
  border-top: 2px solid #EFA00B;
}

.circuit-specialise .container-collapse {
  margin: 2em 0em;
  width: 100%;
  max-width: 500px;
}

@media screen and (min-width: 769px) {
  .circuit-specialise .container-collapse {
    width: 90%;
    max-width: 1000px;
  }
}

.circuit-specialise .container-image-header {
  width: 80%;
  max-width: 250px;
  max-height: 250px;
}

.circuit-specialise .container-image-schema {
  width: 100%;
  max-width: 500px;
}

@media screen and (min-width: 769px) {
  .circuit-specialise .container-image-schema {
    max-width: 900px;
  }
}

.circuit-specialise .container-image-main {
  width: 100%;
  max-width: 500px;
}

@media screen and (min-width: 769px) {
  .circuit-specialise .container-image-main {
    max-width: 900px;
  }
}

.circuit-specialise .text-quiz {
  margin: 2em 0em;
}

.circuit-specialise .container-form {
  width: 100%;
  max-width: 800px;
}

.circuit-specialise .container-comment {
  width: 100%;
  max-width: 800px;
}

.circuit-specialise .container-comment .comment-li {
  margin: 8em 0em;
  -webkit-box-shadow: 0px 0px 20px #272727;
          box-shadow: 0px 0px 20px #272727;
}
