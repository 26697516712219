.quiz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}

.quiz .container-link-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 1.2em auto;
}

.quiz .container-link {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-right: 2em;
  height: 2em;
  border-top: 0px solid #EFA00B;
  padding-top: 10px;
  -webkit-transition: border-top 250ms linear;
  -o-transition: border-top 250ms linear;
  transition: border-top 250ms linear;
}

.quiz .container-link:hover {
  border-top: 6px solid #EFA00B;
}

.quiz__title {
  width: 100%;
  margin: 1em 0em;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .quiz__title {
    width: 80%;
    margin: 2em 0em;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .quiz__title {
    width: 70%;
    margin: 3em 0em;
  }
}

@media screen and (min-width: 993px) {
  .quiz__title {
    width: 70%;
    margin: 10em 0em 5em 0em;
  }
}

.quiz__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 500px;
  background-color: #FBFFFE;
  padding: 1em;
}

@media screen and (min-width: 769px) {
  .quiz__form {
    width: 80%;
    max-width: 800px;
  }
}

.quiz__form__question {
  margin: 2em 0em;
  width: 100%;
}

.quiz__resultat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.quiz__resultat__text {
  font-size: 1.1em;
  padding: 1em;
  color: #272727;
}

.quiz__resultat__rate {
  margin: 1em 0em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5em;
  text-align: center;
  padding: 1em;
  border-radius: 0.3em;
  font-weight: 600;
}

.quiz__resultat__btn-reset {
  margin-top: 2em;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.quiz__error {
  padding: 1em;
  font-size: 1.2em;
  color: #EFA00B;
}

.quiz .color-error {
  color: #EFA00B;
}

.quiz .bad-result {
  background-color: #EFA00B;
}

.quiz .medium-result {
  background-color: #0267C1;
}

.quiz .good-result {
  background-color: #3A7D44;
}
