.collapse-session {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  max-height: 100%;
}

@media screen and (min-width: 769px) {
  .collapse-session:hover .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    max-height: 100px;
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
    -webkit-transition: opacity 500ms, max-height 500ms, -webkit-transform 500ms;
    transition: opacity 500ms, max-height 500ms, -webkit-transform 500ms;
    -o-transition: opacity 500ms, transform 500ms, max-height 500ms;
    transition: opacity 500ms, transform 500ms, max-height 500ms;
    transition: opacity 500ms, transform 500ms, max-height 500ms, -webkit-transform 500ms;
  }
}

.collapse-session .container-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.collapse-session .text {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  font-size: 1em;
  color: #FBFFFE;
  padding: 0.5em;
  text-transform: capitalize;
}

.collapse-session .text .color-name {
  color: #EFA00B;
}

.icon > path {
  fill: #FBFFFE;
}

.collapse-session .list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  background-color: #3A7D44;
}

@media screen and (min-width: 769px) {
  .collapse-session .list {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
}

@media screen and (min-width: 769px) {
  .collapse-session .list {
    opacity: 0;
    max-height: 0px;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: opacity 300ms, max-height 300ms, -webkit-transform 300ms linear;
    transition: opacity 300ms, max-height 300ms, -webkit-transform 300ms linear;
    -o-transition: opacity 300ms, transform 300ms linear, max-height 300ms;
    transition: opacity 300ms, transform 300ms linear, max-height 300ms;
    transition: opacity 300ms, transform 300ms linear, max-height 300ms, -webkit-transform 300ms linear;
  }
}

.list-li {
  background-color: #428e4d;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .list-li:hover {
    cursor: pointer;
    background-color: #88c892;
  }
}

@media screen and (max-width: 768px) {
  .display-none {
    display: none;
  }
}
