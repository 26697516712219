.main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .main-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
  }
}

.main-container .container {
  width: 100%;
  margin: 2em;
  background-color: #f3f3f3;
}

.main-container .container__img {
  width: 100%;
  -webkit-box-shadow: 0px 0px 20px 0px #272727;
          box-shadow: 0px 0px 20px 0px #272727;
  -o-object-fit: contain;
     object-fit: contain;
}

.main-container .container__figcaption {
  background-color: #FBFFFE;
  font-size: 1em;
  padding: 0.5em;
  text-align: center;
}

.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
}

.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
}
