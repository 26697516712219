.container-form {
  position: relative;
}

.container-form .comment-stored {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 3em;
  background-color: #3A7D44;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.container-form .comment-stored__text {
  color: #FBFFFE;
  font-size: 1em;
}

.container-form .comment-stored__cross {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  color: #FBFFFE;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.2em;
  -webkit-box-shadow: 0px 0px 10px #272727;
          box-shadow: 0px 0px 10px #272727;
  cursor: pointer;
}

.container-form .hide {
  display: none;
}
