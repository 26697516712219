.prise-courant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.prise-courant .container-title {
  width: 100%;
  margin: 1em 0em;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .prise-courant .container-title {
    width: 80%;
    margin: 2em 0em;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .prise-courant .container-title {
    width: 70%;
    margin: 3em 0em;
  }
}

@media screen and (min-width: 993px) {
  .prise-courant .container-title {
    width: 60%;
    margin: 10em 0em 5em 0em;
  }
}

.prise-courant .container-collapse {
  margin: 2em 0em;
  width: 100%;
  max-width: 500px;
}

@media screen and (min-width: 769px) {
  .prise-courant .container-collapse {
    width: 90%;
    max-width: 1000px;
  }
}

.prise-courant .container-image-header {
  width: 80%;
  max-width: 250px;
}

@media screen and (min-width: 769px) {
  .prise-courant .container-image-header {
    max-width: 800px;
  }
}

.prise-courant .container-image-schema {
  width: 100%;
  max-width: 500px;
}

@media screen and (min-width: 769px) {
  .prise-courant .container-image-schema {
    max-width: 900px;
  }
}

.prise-courant .container-image-main {
  width: 100%;
  max-width: 500px;
}

@media screen and (min-width: 769px) {
  .prise-courant .container-image-main {
    max-width: 900px;
  }
}

.prise-courant .text-quiz {
  margin: 2em 0em;
}

.prise-courant .container-form {
  width: 100%;
  max-width: 800px;
}

.prise-courant .container-comment {
  width: 100%;
  max-width: 800px;
}

.prise-courant .container-comment .comment-li {
  margin: 8em 0em;
  -webkit-box-shadow: 0px 0px 20px #272727;
          box-shadow: 0px 0px 20px #272727;
}
