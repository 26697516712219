.main-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (min-width: 769px) {
  .main-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    margin-bottom: 50px;
    overflow: visible;
    width: 100%;
  }
}

.container-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 80px;
}

.container-banner__banner {
  background-color: #EFA00B;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 100%;
  background-color: #272727;
}

.header__container-logo {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 20%;
          flex: 1 1 20%;
  max-width: 200px;
  height: 100%;
}

.header__container-logo > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.header__container-logo .header-logo {
  height: 90%;
  width: auto;
}

@media screen and (max-width: 575px) {
  .header__container-logo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 0%;
            flex: 0 0 0%;
  }
}

.header__container-menu {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 75%;
          flex: 1 1 75%;
  height: 100%;
}

.header__container-collapse-session {
  height: 100%;
  margin: 0em 1em;
}

.header__container-div-sitebuilding {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (min-width: 769px) {
  .schrink {
    -webkit-transition: height 100ms ease-in;
    -o-transition: height 100ms ease-in;
    transition: height 100ms ease-in;
    height: 70px;
  }
}

@media screen and (min-width: 769px) {
  .grow {
    -webkit-transition: height 250ms linear;
    -o-transition: height 250ms linear;
    transition: height 250ms linear;
    height: 90px;
  }
}
