.menu-flotant {
  position: fixed;
  top: 150px;
  left: 20px;
}

.menu-flotant .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.menu-flotant .menu__li {
  max-width: 300px;
  color: #EFA00B;
  margin-top: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.menu-flotant .menu__li:hover {
  color: #2c1d02;
  cursor: pointer;
}
