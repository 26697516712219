.collapse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
}

.collapse__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1em;
  border-radius: 1.1em;
  font-family: 'Oswald', sans-serif;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #FBFFFE;
  -webkit-transition: background-color 250ms linear;
  -o-transition: background-color 250ms linear;
  transition: background-color 250ms linear;
}

@media screen and (min-width: 769px) {
  .collapse__title {
    font-size: 1.1em;
  }
}

.collapse__content {
  display: none;
  width: 100%;
  overflow: hidden;
  border-radius: 1em;
  border: none;
  background-color: #FBFFFE;
  color: #272727;
  font-size: 1em;
  -webkit-transition: max-height 300ms linear, opacity 200ms linear;
  -o-transition: max-height 300ms linear, opacity 200ms linear;
  transition: max-height 300ms linear, opacity 200ms linear;
}

@media screen and (min-width: 769px) {
  .collapse__content {
    font-size: 1.1em;
  }
}

.collapse .display {
  display: block;
  max-height: 1500px;
  height: auto;
  padding: 1em;
}

.collapse .hide {
  display: block;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  padding: 0em;
  opacity: 0;
}

.collapse .first-color-title {
  background-color: #0267C1;
}

.collapse .first-color-title:hover {
  background-color: #0382f3;
}

.collapse .first-color-content {
  border: 2px solid #0267C1;
}

.collapse .second-color-title {
  background-color: #EFA00B;
}

.collapse .second-color-title:hover {
  background-color: #f6b437;
}

.collapse .second-color-content {
  border: 2px solid #EFA00B;
}

.collapse .third-color-title {
  background-color: #3A7D44;
}

.collapse .third-color-title:hover {
  background-color: #4aa057;
}

.collapse .third-color-content {
  border: 2px solid #3A7D44;
}

.collapse .fourth-color-title {
  background-color: #272727;
}

.collapse .fourth-color-title:hover {
  background-color: #414141;
}

.collapse .fourth-color-content {
  border: 2px solid #272727;
}

.collapse .first {
  color: #0267C1;
}

.collapse .second {
  color: #EFA00B;
}

.collapse .third {
  color: #3A7D44;
}

.collapse .fourth {
  color: #272727;
}

.collapse .fifth {
  color: #FBFFFE;
}
