.cgu {
  width: 100%;
  margin: 0em auto;
  padding: 1em;
}

@media screen and (min-width: 769px) {
  .cgu {
    width: 70%;
    margin: 2em auto;
    padding: 2em;
  }
}

.cgu__title {
  width: 100%;
  text-align: center;
  color: #272727;
  font-family: 'Oswald', sans-serif;
  font-size: 1.5em;
  text-transform: uppercase;
}

@media screen and (min-width: 769px) {
  .cgu__title {
    width: 100%;
    text-align: center;
    color: #272727;
    font-family: 'Oswald', sans-serif;
    font-size: 3em;
    margin: 1em 0em 2em 0em;
    text-transform: uppercase;
  }
}

.cgu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  margin: 0px auto;
}

@media screen and (min-width: 769px) {
  .cgu__list {
    width: 80%;
  }
}

.cgu__list__li {
  width: 100%;
  padding: 2em 1em;
}

.cgu__list .article-number {
  font-family: 'Oswald', sans-serif;
  font-size: 1.1em;
  margin: 1em 0em;
}

.cgu__list .article-title {
  font-family: 'Oswald', sans-serif;
  font-size: 1.1em;
  padding: 1em 0.5em;
  color: #0267C1;
}

@media screen and (min-width: 769px) {
  .cgu__list .article-title {
    display: inline-block;
  }
}

.cgu__list .article-content {
  font-size: 1em;
  line-height: 2em;
  text-align: justify;
}

.cgu .bg-dark {
  background-color: #f3f3f3;
}
