@charset "UTF-8";
.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .title {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

.title__container-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .title__container-text {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.title__container-text .title-img {
  width: 150px;
}

.title .title-home,
.title .title-page {
  width: 100%;
  text-align: center;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: #272727;
  font-size: 1.2em;
  border-right: 50px solid #EFA00B;
  border-left: 50px solid #EFA00B;
  -webkit-text-decoration: underline #EFA00B;
          text-decoration: underline #EFA00B;
}

@media screen and (min-width: 769px) {
  .title .title-home,
  .title .title-page {
    font-size: 2em;
    margin: 0.8em 0em;
  }
}

@media screen and (min-width: 993px) {
  .title .title-home,
  .title .title-page {
    font-size: 2.5em;
    margin: 0.8em 0em;
  }
}

.title .title-home {
  font-size: 1.2em;
  color: #FBFFFE;
  border: none;
  text-decoration: none;
  /*@include under-large-screen {
      font-size: 2em;
      margin: 0.8em 0em;
    }

    //responsive pour ecran small
    @include small-screen {
      font-size: 1.2em;
      margin: 0.5em 0em;
    }*/
}

@media screen and (min-width: 769px) {
  .title .title-home {
    font-size: 2em;
    margin: 0.8em 0em;
  }
}

@media screen and (min-width: 993px) {
  .title .title-home {
    font-size: 3em;
    margin: 0.8em 0em;
  }
}

.title .text-home,
.title .text-page {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1em;
  font-style: italic;
  color: #272727;
  text-align: center;
  padding: 1em 0em;
  /*//responsive pour ecran inferieur à large
    @include under-large-screen {
      font-size: 1.5em;
      margin: 0.8em 0em;
    }

    //responsive pour ecran small
    @include small-screen {
      font-size: 1em;
      margin: 0.5em 0em;
    }*/
}

@media screen and (min-width: 769px) {
  .title .text-home,
  .title .text-page {
    font-size: 1.5em;
    margin: 0.8em 0em;
  }
}

@media screen and (min-width: 993px) {
  .title .text-home,
  .title .text-page {
    font-size: 2em;
    margin: 0.8em 0em;
  }
}

.title .text-home {
  color: #FBFFFE;
}

.title .first-color {
  color: #0267C1;
}

.title .second-color {
  color: #EFA00B;
}

.title .third-color {
  color: #3A7D44;
}

.title .fourth-color {
  color: #272727;
}

.title .fifth-color {
  color: #FBFFFE;
}
