.banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 1.5em;
  -webkit-transition: border-radius 250ms linear;
  -o-transition: border-radius 250ms linear;
  transition: border-radius 250ms linear;
}

@media screen and (max-width: 575px) {
  .banner {
    border-radius: 0em;
  }
}

.banner__background-img, .banner__img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 1.5em;
}

@media screen and (max-width: 575px) {
  .banner__background-img, .banner__img {
    border-radius: 0em;
  }
}

.banner__img {
  opacity: 0.9;
}

.banner__title {
  z-index: 10;
}

@media screen and (max-width: 575px) {
  .banner__title {
    font-size: 1.5em;
    margin-left: 4em;
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .banner__title {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .banner__title {
    font-size: 2em;
  }
}

@media screen and (min-width: 993px) and (max-width: 1500px) {
  .banner__title {
    font-size: 2.2em;
  }
}

@media screen and (min-width: 1501px) {
  .banner__title {
    font-size: 3em;
  }
}

.banner__text {
  z-index: 10;
}

@media screen and (max-width: 575px) {
  .banner__text {
    font-size: 1em;
    margin-left: 4em;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .banner__text {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .banner__text {
    font-size: 1em;
  }
}

@media screen and (min-width: 993px) and (max-width: 1500px) {
  .banner__text {
    font-size: 2em;
  }
}

@media screen and (min-width: 1501px) {
  .banner__text {
    font-size: 2em;
  }
}

.banner .color-text-first {
  color: #0267C1;
}

.banner .color-text-second {
  color: #EFA00B;
}

.banner .color-text-third {
  color: #3A7D44;
}

.banner .color-text-fourth {
  color: #272727;
}

.banner .color-text-fifth {
  color: #FBFFFE;
}

.banner .color-bg-first {
  background-color: #0267C1;
}

.banner .color-bg-second {
  background-color: #EFA00B;
}

.banner .color-bg-third {
  background-color: #3A7D44;
}

.banner .color-bg-fourth {
  background-color: #272727;
}

.banner .color-bg-fifth {
  background-color: #FBFFFE;
}
