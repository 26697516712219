.nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  height: 100%;
}

.nav-link .nav-link-text {
  font-family: "Oswald", sans-serif;
  text-transform: lowercase;
}

.nav-link .nav-link-text::first-letter {
  text-transform: uppercase;
}

.nav-link .nav-link-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.nav-link .first-bg {
  background-color: #0267C1;
}

.nav-link .second-bg {
  background-color: #EFA00B;
}

.nav-link .third-bg {
  background-color: #3A7D44;
}

.nav-link .fourth-bg {
  background-color: #272727;
}

.nav-link .fifth-bg {
  background-color: #FBFFFE;
}

.nav-link .first-text {
  color: #0267C1;
}

.nav-link .second-text {
  color: #EFA00B;
}

.nav-link .third-text {
  color: #3A7D44;
}

.nav-link .fourth-text {
  color: #272727;
}

.nav-link .fifth-text {
  color: #FBFFFE;
}
