.nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .nav-menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    height: 100%;
  }
}

.nav-menu__li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 0.5em 0em 0.5em 0em;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .nav-menu__li {
    margin: 0em 1em;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 15%;
            flex: 0 1 15%;
    border-top: 0px solid #EFA00B;
    border-bottom: 0px solid #EFA00B;
    height: 100%;
    -webkit-transition: border-top 250ms linear 0s, border-bottom 250ms linear 0s;
    -o-transition: border-top 250ms linear 0s, border-bottom 250ms linear 0s;
    transition: border-top 250ms linear 0s, border-bottom 250ms linear 0s;
  }
  .nav-menu__li:hover {
    border-top: 6px solid #EFA00B;
  }
}
