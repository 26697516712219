@-webkit-keyframes landing {
  0% {
    -webkit-transform: translateY(-5em);
            transform: translateY(-5em);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
    opacity: 1;
  }
}

@keyframes landing {
  0% {
    -webkit-transform: translateY(-5em);
            transform: translateY(-5em);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
    opacity: 1;
  }
}

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 2em auto;
  padding: 1em;
  -webkit-box-shadow: 0px 0px 20px #272727;
          box-shadow: 0px 0px 20px #272727;
  -webkit-animation: landing 500ms linear 0s 1 normal forwards;
          animation: landing 500ms linear 0s 1 normal forwards;
}

@media screen and (min-width: 769px) {
  .form {
    width: 80%;
    max-width: 800px;
  }
}

.form__text {
  font-size: 1em;
  text-align: left;
  margin: 2em 0em;
  color: #272727;
  word-spacing: 0.2em;
  line-height: 2em;
}

@media screen and (min-width: 769px) {
  .form__text {
    font-size: 1.1em;
  }
}

.form__text > span {
  display: inline-block;
  font-size: 1.2em;
  font-style: italic;
  font-weight: 600;
  text-transform: lowercase;
}

.form__text > span::first-letter {
  text-transform: uppercase;
}

@media screen and (min-width: 769px) {
  .form__text > span {
    font-size: 1.4em;
  }
}

.form .cont-label-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 2em 0em;
  width: 100%;
  -webkit-animation: landing 500ms linear 0.1s 1 normal forwards;
          animation: landing 500ms linear 0.1s 1 normal forwards;
}

.form .form-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  font-weight: 600;
  color: #272727;
}

.form .form-label .requis {
  color: #3A7D44;
}

@media screen and (min-width: 769px) {
  .form .form-label {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.form .text-label {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.form .text-label::first-letter {
  text-transform: uppercase;
}

@media screen and (min-width: 769px) {
  .form .text-label {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    width: 50%;
  }
}

.form .form-input {
  width: 80%;
  height: 2em;
  padding: 0.5em;
  border-bottom: 2px solid #0267C1;
  font-size: 1em;
  color: #272727;
  background: none;
  -webkit-transition: border-bottom 250ms linear;
  -o-transition: border-bottom 250ms linear;
  transition: border-bottom 250ms linear;
}

@media screen and (min-width: 769px) {
  .form .form-input {
    width: 50%;
  }
}

.form .form-input:focus {
  border-bottom: 2px solid #c4e3fe;
}

.form .container-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.form .container-checkbox .text-checkbox {
  width: 80%;
}

.form .container-checkbox .checkbox {
  all: revert;
  margin-right: 5%;
  height: 1em;
  width: 1em;
  border: 2px solid gray;
}

.form__flag {
  margin: 0.5em 0em;
  font-size: 1em;
  text-align: left;
}

.form .textarea {
  resize: none;
  margin-top: 1em;
  width: 80%;
  height: 200px;
  border: 2px solid #0267C1;
  padding: 0.5em;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -webkit-transition: border 250ms linear;
  -o-transition: border 250ms linear;
  transition: border 250ms linear;
}

@media screen and (min-width: 769px) {
  .form .textarea {
    width: 50%;
  }
}

.form .textarea:focus {
  border: 2px solid #c4e3fe;
}

.form .cont-valid-btn {
  -webkit-animation: landing 250ms linear 0s 1 normal forwards;
          animation: landing 250ms linear 0s 1 normal forwards;
}

.form .invalid-text {
  color: #EFA00B;
}

.form .valid-text {
  color: #3A7D44;
}

.form .valid-border {
  border-bottom: 2px solid #3A7D44;
  color: #3A7D44;
}

.form .valid-border-textarea {
  border: 2px solid #3A7D44;
  color: #3A7D44;
}

.form .color-readonly {
  color: #5a5a5a;
}

.form .comment-stored {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 3em;
  background-color: #3A7D44;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.form .comment-stored__text {
  color: #FBFFFE;
  font-size: 1em;
}

.form .comment-stored__cross {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  color: #FBFFFE;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.2em;
  -webkit-box-shadow: 0px 0px 10px #272727;
          box-shadow: 0px 0px 10px #272727;
  cursor: pointer;
}

.form .unsaved {
  background-color: #EFA00B;
}

.form .hide {
  display: none;
}

.form .color-readonly {
  color: #8d8d8d;
}

.form .border-readonly {
  border-bottom: 2px solid #8d8d8d;
}
