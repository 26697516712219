.button__std {
  padding: 1em;
  font-size: 1em;
  border: none;
  width: 90%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  -webkit-transition: background-color 250ms linear;
  -o-transition: background-color 250ms linear;
  transition: background-color 250ms linear;
}

@media screen and (min-width: 993px) {
  .button__std {
    font-size: 1.2em;
  }
}

.button .first-bg {
  background-color: #0267C1;
}

.button .first-bg:hover {
  background-color: #014c8f;
}

.button .second-bg {
  background-color: #EFA00B;
}

.button .second-bg:hover {
  background-color: #be7f09;
}

.button .third-bg {
  background-color: #3A7D44;
}

.button .third-bg:hover {
  background-color: #2a5a31;
}

.button .fourth-bg {
  background-color: #272727;
}

.button .fourth-bg:hover {
  background-color: #0e0e0e;
}

.button .fifth-bg {
  background-color: #FBFFFE;
}

.button .fifth-bg:hover {
  background-color: #c8fff1;
}

.button .first-text {
  color: #0267C1;
}

.button .second-text {
  color: #EFA00B;
}

.button .third-text {
  color: #3A7D44;
}

.button .fourth-text {
  color: #272727;
}

.button .fifth-text {
  color: #FBFFFE;
}

.button .disabled {
  opacity: 0.4;
  background-color: #EFA00B;
}

.button .disabled:hover {
  background-color: #EFA00B;
}
