.prise-courant .description, .prise-courant .precaution {
  counter-set: compteur;
}

.prise-courant .description__text, .prise-courant .precaution__text {
  margin-top: 0.5em;
  font-size: 1.2em;
  font-weight: 600;
  font-style: italic;
  color: #EFA00B;
}

.prise-courant .description__li::before, .prise-courant .precaution__li::before {
  content: counter(compteur);
  display: block;
  color: #0267C1;
  font-weight: 600;
  font-size: 1.1em;
  width: auto;
  text-align: left;
}

@media screen and (min-width: 769px) {
  .prise-courant .description__li::before, .prise-courant .precaution__li::before {
    display: inline-block;
    margin-right: 1em;
  }
}

.prise-courant .description__li, .prise-courant .precaution__li {
  counter-increment: compteur;
  margin-top: 1.2em;
  font-size: 1.2em;
}

.prise-courant .description .important, .prise-courant .precaution .important {
  color: #efa00b;
}

.prise-courant .principe, .prise-courant .astuce, .prise-courant .savoir {
  font-size: 1.2em;
}

.prise-courant .principe__text, .prise-courant .astuce__text, .prise-courant .savoir__text {
  line-height: 1.5em;
  margin: 0.5em 0em;
}
