.container-chevron {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 2em;
  -webkit-transition: -webkit-transform 250ms linear;
  transition: -webkit-transform 250ms linear;
  -o-transition: transform 250ms linear;
  transition: transform 250ms linear;
  transition: transform 250ms linear, -webkit-transform 250ms linear;
}

.rotate {
  -webkit-transform: rotateZ(180deg);
      -ms-transform: rotate(180deg);
          transform: rotateZ(180deg);
}

.arrow-size {
  font-size: 1em;
}

@media screen and (max-width: 992px) {
  .arrow-size {
    font-size: 1.2em;
  }
}

.first-color {
  color: #0267C1;
}

.second-color {
  color: #EFA00B;
}

.third-color {
  color: #3A7D44;
}

.fourth-color {
  color: #272727;
}

.fifth-color {
  color: #FBFFFE;
}
