.site-building {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-height: 400px;
  width: 90vw;
  max-width: 400px;
  padding: 1em;
  border: 4px solid #EFA00B;
  border-radius: 10px;
  background-color: #4d4d4d;
}

.site-building__title {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  font-size: 1.1em;
  color: #EFA00B;
  margin-bottom: 0.8em;
}

@media screen and (min-width: 769px) {
  .site-building__title {
    font-size: 1.5em;
  }
}

.site-building__text {
  margin-top: 1em;
  -ms-flex-item-align: start;
      align-self: flex-start;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  font-size: 1em;
  color: #FBFFFE;
}

@media screen and (min-width: 769px) {
  .site-building__text {
    font-size: 1.2em;
  }
}

.site-building__text-end {
  margin-top: 1em;
  -ms-flex-item-align: start;
      align-self: flex-start;
  color: #FBFFFE;
  font-size: 1.1em;
}

@media screen and (min-width: 769px) {
  .site-building__text-end {
    font-size: 1.2em;
  }
}

.site-building__closure {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -ms-flex-item-align: end;
      align-self: flex-end;
  font-size: 1em;
  padding: 5px;
  color: #FBFFFE;
  border: 2px solid #FBFFFE;
  border-radius: 10px;
  background-color: lighten #272727, 100%;
}

.site-building__closure:hover {
  cursor: pointer;
  background-color: #272727;
}

.site-building__icon {
  position: absolute;
  top: 1em;
  left: 2em;
  width: 2em;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.site-building__icon > img {
  width: 100%;
}

.site-building div[name="2"] {
  position: absolute;
  top: 1em;
  left: 100%;
  -webkit-transform: translateX(-4em);
      -ms-transform: translateX(-4em);
          transform: translateX(-4em);
}

.site-building-hide {
  display: none;
}
