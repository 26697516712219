.title-n {
  width: 100%;
}

.title-n .first-color {
  color: #0267C1;
}

.title-n .second-color {
  color: #EFA00B;
}

.title-n .third-color {
  color: #3A7D44;
}

.title-n .fourth-color {
  color: #272727;
}

.title-n .fifth-color {
  color: #FBFFFE;
}
